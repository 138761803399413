import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CartItem } from './cart.model';

export interface CartState extends EntityState<CartItem, string>, ActiveState {
	editor: {
		/** This is used to understand what creative unit a user is interacting with. */
		activeId: string;
	}
}

const initialState: CartState = {
	active: null,
	editor: {
		activeId: null
	}
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
	name: 'cart-item'
})
export class CartStore extends EntityStore<CartState> {
	constructor() {
		super(initialState);
	}
}
