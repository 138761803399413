import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { materialModules } from '../../../angular-material.module';
import { BaseInputComponent } from './base-input/base-input.component';
import { GroupInputComponent } from './group-input/group-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { InputComponent } from './input/input.component';
import { ToggleInputComponent } from './toggle-input/toggle-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { JsonInputComponent } from './json-input/json-input.component';
import { MultiselectInputComponent } from './multiselect-input/multiselect-input.component';
import { RequireSelectionDirective } from './multiselect-input/require-selection.directive';
import { RangeInputComponent } from './range-input/range-input.component';
import { MultiAddInputComponent } from './multi-add-input/multi-add-input.component';
import {SharedDirectiveModule} from "../../directives/shared-directive.module";
import { SetLocaleEditButtonComponent } from './set-locale-edit-button/set-locale-edit-button.component';
import { SharedPipeModule } from "../../pipes/shared-pipe.module";
import { DynamicJsonFormComponent } from './dynamic-json-form/dynamic-json-form.component';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { MonacoInputComponent } from './monaco-input/monaco-input.component';
import { CSSUnitValueInputComponent } from './css-unit-value-input/css-unit-value-input.component';
import { NgxColorsModule } from 'ngx-colors';
import { WysiwygInputComponent } from './wysiwyg-input/wysiwyg-input.component';
import { QuillModule } from 'ngx-quill';
import { MergeTagInputComponent } from "./merge-tag-input/merge-tag-input.component";
import { TextChipInputComponent } from "./merge-tag-input/text-chip-input/text-chip-input.component";
import { A11yModule } from "@angular/cdk/a11y";

const monacoConfig: NgxMonacoEditorConfig = {
	requireConfig: {
		entry: {
			'editor.worker': 'monaco-editor/esm/vs/editor/editor.worker.js',
		}
	} // allows to overide configuration passed to monacos loader
};

@NgModule({
	declarations: [
		BaseInputComponent,
		GroupInputComponent,
		SelectInputComponent,
		TextInputComponent,
 		InputComponent,
   		ToggleInputComponent,
		TextareaInputComponent,
 		JsonInputComponent,
     	MultiselectInputComponent,
	 	RequireSelectionDirective,
   		RangeInputComponent,
     	MultiAddInputComponent,
		SetLocaleEditButtonComponent,
		DynamicJsonFormComponent,
		MonacoInputComponent,
		CSSUnitValueInputComponent,
		WysiwygInputComponent,
		MergeTagInputComponent,
		TextChipInputComponent
	],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
		SharedDirectiveModule,
		SharedPipeModule,
		MonacoEditorModule.forRoot(monacoConfig),
		QuillModule.forRoot(),
		NgxColorsModule,
		...materialModules,
		A11yModule
	],
	providers: [],
	exports: [
		GroupInputComponent,
		SelectInputComponent,
		TextInputComponent,
		ToggleInputComponent,
		InputComponent,
		TextareaInputComponent,
		JsonInputComponent,
		MultiselectInputComponent,
		RangeInputComponent,
		MultiAddInputComponent,
		SetLocaleEditButtonComponent,
		DynamicJsonFormComponent,
		MonacoInputComponent,
		CSSUnitValueInputComponent,
		SharedPipeModule,
		WysiwygInputComponent,
		MergeTagInputComponent,
		TextChipInputComponent
	]
})
export class InputModule {}
