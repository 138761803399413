import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';

/**
 * Base class for all input components.
 * Any utilities or common functionality should be added here.
 */
@Component({
	selector: 'app-base-input',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseInputComponent implements OnInit, OnChanges, OnDestroy {

	// Inputs to use if you have a form group.
	@Input() parentForm: UntypedFormGroup; // The parent form group.
	@Input() controlName?: string = 'key';	// The name of the control in the parent form.

	// Inputs to use if you have no form group.
	@Input() currentValue: any; // The current value of the input.  This will override the value of the parent form control.
	@Input() validators: ValidatorFn | ValidatorFn[]; // The validators to use for the input.
	@Input() options: any[]; // The options to use for the input.
	@Input() optionLabelKey: string; // The name of the property to use for the label of the option.
	@Input() optionValueKey: string; // The name of the property to use for the value of the option.

	// Event Configuration
	@Input() patchChanges: boolean = true; // Whether to patch the parent form with the value of this input.
	@Input() debounceTime: number = 0; // The debounce time to use for the input.

	// Template Configuration
	@Input() label?: string; // The label to use for the input.
	@Input() placeholder?: string; // The placeholder to use for the input.\
	@Input() hint?: string; // The hint to use for the input.
	@Input() floatLabel?: string = 'always'; // The float label to use for the input.
	@Input() isDisabled?: boolean = false; // Whether the input is disabled.
	@Input() fullWidth?: boolean = false; // Whether the input should be full width.
	@Input() formFieldCss= 'form-control form-field dark-color'; // The css class to use for the input field.

	@Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

	public form: UntypedFormGroup;

	/** Allows higher level components to manipulate the current value before it hits the form. */
	public currentValueOverride: any;

	public _unsubscribe: Subject<void> = new Subject<void>();

	constructor() { }

	ngOnInit(): void {
		// Create a new form group with the same validator as the parent form control.
		// This is to have finer control over whether we want to patch the parent form or bubble
		// up the value to the parent component.
		// console.log('BaseInputComponent.ngOnInit', this.label, this.currentValueOverride, this.currentValue, this.parentForm?.get(this.controlName).value, this.validators);
		this.initializeForm(this.currentValueOverride);
		this.initializeFormListener();
	}

	initializeForm(currentValue?: any, validators?: ValidatorFn | ValidatorFn[]) {
		this.form = new UntypedFormGroup({
			[this.controlName]: new UntypedFormControl(
				{ value: currentValue || this.currentValue || this.parentForm?.get(this.controlName).value, disabled: this.isDisabled },
				validators || this.validators || this.parentForm?.get(this.controlName).validator
			)
		});
	}

	initializeFormListener() {
		// Listen for changes to the form and emit the value.
		this.form.valueChanges.pipe(
			takeUntil(this._unsubscribe),
			debounceTime(this.debounceTime)
		).subscribe(value => {
			// console.log('BaseInputComponent.valueChanges', value);
			if (this.patchChanges && this.parentForm) {
				this.parentForm.patchValue(value);
			}
			this.emitValueChange(value[this.controlName]);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.currentValue && !changes.currentValue.firstChange) {
			// console.log('BaseInputComponent.ngOnChanges', changes.currentValue.currentValue, this.label, changes.currentValue.firstChange);
			this.handleCurrentValueChange(changes.currentValue.currentValue);
		}

		if (changes.isDisabled && !changes.isDisabled.firstChange) {
			this.handleIsDisabled(changes.isDisabled.currentValue);
		}
	}

	handleCurrentValueChange(value: any): void {
		// console.log('BaseInputComponent.handleCurrentValueChange', value);
		this.form?.patchValue({ [this.controlName]: value }, { emitEvent: false });
	}

	handleIsDisabled(state: boolean): void {
		if (state) {
			this.form.get(this.controlName)?.disable();
		} else {
			this.form?.get(this.controlName)?.enable();
		}
	}

	changeValue(value: any): void {
		// console.log('BaseInputComponent.changeValue', value);
		this.form.patchValue({ [this.controlName]: value });
	}

	emitValueChange(value: any): void {
		// console.log('BaseInputComponent.emitValueChange', value);
		this.valueChange.emit(value);
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}

	clearForm(): void {
		this.form.reset();
	}
}
