import { IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { LayerTextDto } from './layer-text.dto';
import { AssetDto } from '../../../asset/models/asset.dto';
import { LayerVideoDto } from './layer-video.dto';
import { LayerAudioDto } from './layer-audio.dto';
import { LayerDto } from './layer.dto';
import { LayerOptimizationsDto } from './layer-optimization.dto';

export class CustomizationOptionLayerDto {
	@IsString()
	id: string;

	@IsString()
	@IsOptional()
	label: string;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerTextDto)
	text?: LayerTextDto;

	@IsOptional()
	image?: AssetDto;

	@IsOptional()
	@ValidateNested()
	@Type(() => LayerVideoDto)
	video?: LayerVideoDto;

	@IsOptional()
	@IsString()
	assetPath?: string;

	@IsOptional()
	style?: any;

	@IsOptional()
	optimizations?: LayerOptimizationsDto;

	@IsOptional()
	previewOptimizations?: LayerOptimizationsDto;
}
