import { ObjectUtils } from "./utils.object";

export class Data {
	public static base64ToBuffer(imageData: string): [Buffer, string, string] {
		let mime = imageData.split(';')[0].replace('data:', '');
		let ext = '.' + mime.split('/')[1];

		let buf = Buffer.from(imageData.split('base64,')[1], 'base64');

		return [buf, mime, ext];
	}

	public static arrayToCsvString(data: any[], fields: { id: string, title: string }[], ignoreHeader: boolean = false): string {
		if (!fields?.length) {
			return '';
		}

		const header = fields.map(({title}) => title).join(',');
		const content =  data?.map(row => fields.map(({id}) => {
			let value = ObjectUtils.resolveDotNotationPath(id, row);
			if (value == null) value = '';
			if (typeof value === "string" && value.includes(",")) value = `"${value}"`;
			return value;
		}).join(',')) || [];

		if (ignoreHeader) {
			return content.join('\n');
		}

		return [header, ...content].join('\n');
	}
}


export const northAmericanStates = [
	{ value: 'AK', text: 'Alaska' },
	{ value: 'AL', text: 'Alabama' },
	{ value: 'AR', text: 'Arkansas' },
	{ value: 'AS', text: 'American Samoa' },
	{ value: 'AZ', text: 'Arizona' },
	{ value: 'CA', text: 'California' },
	{ value: 'CO', text: 'Colorado' },
	{ value: 'CT', text: 'Connecticut' },
	{ value: 'DC', text: 'District of Columbia' },
	{ value: 'DE', text: 'Delaware' },
	{ value: 'FL', text: 'Florida' },
	{ value: 'GA', text: 'Georgia' },
	{ value: 'GU', text: 'Guam' },
	{ value: 'HI', text: 'Hawaii' },
	{ value: 'IA', text: 'Iowa' },
	{ value: 'ID', text: 'Idaho' },
	{ value: 'IL', text: 'Illinois' },
	{ value: 'IN', text: 'Indiana' },
	{ value: 'KS', text: 'Kansas' },
	{ value: 'KY', text: 'Kentucky' },
	{ value: 'LA', text: 'Louisiana' },
	{ value: 'MA', text: 'Massachusetts' },
	{ value: 'MD', text: 'Maryland' },
	{ value: 'ME', text: 'Maine' },
	{ value: 'MI', text: 'Michigan' },
	{ value: 'MN', text: 'Minnesota' },
	{ value: 'MO', text: 'Missouri' },
	{ value: 'MS', text: 'Mississippi' },
	{ value: 'MT', text: 'Montana' },
	{ value: 'NC', text: 'North Carolina' },
	{ value: 'ND', text: 'North Dakota' },
	{ value: 'NE', text: 'Nebraska' },
	{ value: 'NH', text: 'New Hampshire' },
	{ value: 'NJ', text: 'New Jersey' },
	{ value: 'NM', text: 'New Mexico' },
	{ value: 'NV', text: 'Nevada' },
	{ value: 'NY', text: 'New York' },
	{ value: 'OH', text: 'Ohio' },
	{ value: 'OK', text: 'Oklahoma' },
	{ value: 'OR', text: 'Oregon' },
	{ value: 'PA', text: 'Pennsylvania' },
	{ value: 'PR', text: 'Puerto Rico' },
	{ value: 'RI', text: 'Rhode Island' },
	{ value: 'SC', text: 'South Carolina' },
	{ value: 'SD', text: 'South Dakota' },
	{ value: 'TN', text: 'Tennessee' },
	{ value: 'TX', text: 'Texas' },
	{ value: 'UT', text: 'Utah' },
	{ value: 'VA', text: 'Virginia' },
	{ value: 'VI', text: 'Virgin Islands' },
	{ value: 'VT', text: 'Vermont' },
	{ value: 'WA', text: 'Washington' },
	{ value: 'WI', text: 'Wisconsin' },
	{ value: 'WV', text: 'West Virginia' },
	{ value: 'WY', text: 'Wyoming' }
];

export const canadianProvincesTerritories = [
	{ value: "AB", text: "Alberta" },
	{ value: "BC", text: "British Columbia" },
	{ value: "MB", text: "Manitoba" },
	{ value: "NB", text: "New Brunswick" },
	{ value: "NL", text: "Newfoundland and Labrador" },
	{ value: "NS", text: "Nova Scotia" },
	{ value: "ON", text: "Ontario" },
	{ value: "PE", text: "Prince Edward Island" },
	{ value: "QC", text: "Quebec" },
	{ value: "SK", text: "Saskatchewan" },
	{ value: "NT", text: "Northwest Territories" },
	{ value: "NU", text: "Nunavut" },
	{ value: "YT", text: "Yukon" },
];

export const chineseProvincesTerritories = [
	{ value: "AH", text: "Anhui" },
	{ value: "FJ", text: "Fujian" },
	{ value: "GS", text: "Gansu" },
	{ value: "GD", text: "Guangdong" },
	{ value: "GZ", text: "Guizhou" },
	{ value: "HI", text: "Hainan" },
	{ value: "HE", text: "Hebei" },
	{ value: "HL", text: "Heilongjiang" },
	{ value: "HA", text: "Henan" },
	{ value: "HB", text: "Hubei" },
	{ value: "HN", text: "Hunan" },
	{ value: "JS", text: "Jiangsu" },
	{ value: "JX", text: "Jiangxi" },
	{ value: "JL", text: "Jilin" },
	{ value: "LN", text: "Liaoning" },
	{ value: "QH", text: "Qinghai" },
	{ value: "SN", text: "Shaanxi" },
	{ value: "SD", text: "Shandong" },
	{ value: "SX", text: "Shanxi" },
	{ value: "SC", text: "Sichuan" },
	{ value: "YN", text: "Yunnan" },
	{ value: "ZJ", text: "Zhejiang" },
];

export enum Country {
	US = "US",
	CA = "CA",
	CN = "CN"
}

export const CountryToStateMap = {
	[Country.US]: northAmericanStates,
	[Country.CA]: canadianProvincesTerritories,
	[Country.CN]: chineseProvincesTerritories
}

export const defaultSignUpMetadata = {
	name: {
		default: "",
		validators: {
			required: true
		}
	},
	zip: {
		default: "",
		validators: {
			required: true
		}
	},
	city: {
		default: "",
		validators: {
			required: true
		}
	},
	phone: {
		default: "",
		validators: {
			required: true,
			pattern: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$"
		}
	},
	state: {
		default: "",
		validators: {
			required: true
		}
	},
	address: {
		default: "",
		validators: {
			required: true
		}
	},
	country: {
		default: Country.US,
		validators: {
			required: true
		}
	},
	website: {
		default: "",
		validators: {
			required: true,
			pattern: "^(https?:\\/\\/)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)\\/?$"
		}
	}
}
